import { useRef } from 'react';
import { usePersonalInfoContext } from '../../../common/providers/personal-info-provider';
import Dropdown, { } from 'react-dropdown';
import ReactDropdown from 'react-dropdown';

export default function ThisIsMeCompactComponent() {

    const {
        labels,
        insideMenu,
        confirm,
        ctaClick,
        isNameHidden,
        nameTabindex,
        name,
        handleNameChange,
        handleNameBlur,
        genderTabindex,
        gender,
        handleGenderChange,
        handleDropdownFocus,
        genderOptions,
        allowAgeInput,
        ageTabIndex,
        MIN_AGE,
        MAX_AGE,
        age,
        handleAgeChange,
        handleAgeBlur,
        hasError,
        setAllowAgeInput,
        onSave,
        setToDefault
      } = usePersonalInfoContext();

      const genderRef = useRef<ReactDropdown>(null);

    return <div className="personal-component-compact">
        {!isNameHidden && <input
        className="field-value"
        type="text"
        tabIndex={nameTabindex}
        value={name}
        placeholder={labels.PERSONAL.NAME_PLACEHOLDER}
        onChange={handleNameChange}
        onBlur={handleNameBlur}
        />}
      <Dropdown
        ref={genderRef}
        controlClassName="dropdown-gender"
        arrowClosed={<span  tabIndex={genderTabindex} className="icon-down-arrow" />}
        arrowOpen={<span className="icon-up-arrow" />}
        baseClassName="Dropdown"
        value={gender}
        onChange={handleGenderChange}
        onFocus={() => handleDropdownFocus(genderRef)}
        options={genderOptions}
      />
    <div className="field-group">
      <div className="field-label-container">
        <label className="field-label">{labels.PERSONAL.AGE}:</label>
        <div className="field-label-right">
        {allowAgeInput?
            <input
            inputMode="numeric"
            className={`age-input field-value field-value-age ${hasError ? 'error error-border' : ''}`}
            type="text"
            tabIndex={ageTabIndex}
            min={MIN_AGE}
            max={MAX_AGE}
            value={age==0?'':age}
            onChange={handleAgeChange}
            onBlur={handleAgeBlur}
          />
          :
          <label className={`field-label-age-value ${hasError ? 'error': ''}`} onClick={() => (setAllowAgeInput(!allowAgeInput))}>{age}
          {hasError ? <span className="error"><label className="icon-error"></label></span> :''}
          </label>
        }
        </div>
      </div>
      <div className="field-label-container">
      <label className="field-label age-label">{MIN_AGE}</label>
      <input
        className="field-value age-slider"
        type="range"
        tabIndex={ageTabIndex}
        min={MIN_AGE}
        max={MAX_AGE}
        value={age}
        step={1}
        onBlur={handleAgeBlur}
        onTouchEnd={handleAgeBlur}
        onChange={(event) => handleAgeChange(event, false)}
      />
      <label className="field-label age-label">{MAX_AGE}</label>
      </div>
    </div>
    {insideMenu ? 
      <><div className="field-group">
        <button className="button button--big button--big--solid" onClick={onSave}>
          {labels.SAVE}
        </button><br />
        <button className="button button--big" onClick={setToDefault}>
          {labels.CANCEL}
        </button>
      </div>
      <div></div>
      </>
      :
      <button className="button cta-button" onClick={ctaClick}>
          {confirm}
        </button>
    }
  </div>;
}