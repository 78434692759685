import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import { selectTenantConfigDefaultLang } from "../../../common/store/tenant-config/tenant-config.slice";
import { LanguageSelectComponentLabels } from "../../../common/labels/language-select-component.labels";
import { LanguageItem } from "../../../common/labels/menu-component.labels";
import "./language-select-component.scss";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../../common/hooks/useTrackEvent";
import { selectUserSettingsState, setUserSettingsLang } from "../../../common/store/user-settings.slice";

export interface LanguageSelectComponentProps {
    showSelected?: boolean;
    useDesc?: boolean;
    labels: LanguageSelectComponentLabels;
    className?: string;
}

type LangLabelMapping = {
    [key:string]: string;
    ENG: string;
    EN: string;
    DE: string;
}

export function LanguageSelectComponent(props: LanguageSelectComponentProps) {
    const langLabelMapping: LangLabelMapping = {
        ENG: 'EN',
        EN: 'ENG',
        DE: 'DE'
    };

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const configDefaultLang = useAppSelector(selectTenantConfigDefaultLang);
    const userSettings = useAppSelector(selectUserSettingsState);

    const dispatch = useAppDispatch();

    const [lang, setLang] = useState<string | undefined>(userSettings.lang ?? configDefaultLang);

    const trackEvent = useTrackEvent();

    const getLangDesc = (selectedLangItem: LanguageItem) => {
        return props.useDesc ? selectedLangItem.DESC : selectedLangItem.VALUE;
    }

    const getDefaultLangDesc = () => {
        const langKey: string = langLabelMapping[lang?.toUpperCase() ?? "ENG"] ?? "ENG";
        switch (langKey) {
            case "ENG":
                return getLangDesc(props.labels.LANGUAGE.ENG);
            case "DE":
                return getLangDesc(props.labels.LANGUAGE.DE);
            default:
                return getLangDesc(props.labels.LANGUAGE.ENG);
        }
    }

    const [langDesc, setLangDesc] = useState<string>(getDefaultLangDesc());

    const open = () => {
        trackEvent({ category: trackEventCategories.LANGUAGE, action: trackEventActions.CLICK, name: trackEventNames.OPEN });
        setIsOpen(!isOpen)
    }

    const setLangInternal = (newLangValue: string, selectedLangItem: LanguageItem) => {
        trackEvent({ category: trackEventCategories.LANGUAGE, action: trackEventActions.CLICK, name: trackEventNames.CHANGE });
        setIsOpen(false);
        setLang(newLangValue);
        setLangDesc(getLangDesc(selectedLangItem))
    }

    useEffect(() => {
        if (lang && lang !== "") {
            dispatch(setUserSettingsLang(lang));
        }
      }, [lang]);

    return <div className="language-select-component">
        <div
            onClick={open}
            className="language-select-component-selected">
            <span>{props.showSelected ? (langDesc ?? props.labels.LANGUAGE.TITLE) : props.labels.LANGUAGE.TITLE}</span>
            <span className={`language-select-component-selected-icon icon-arrow ${isOpen?'icon-up-arrow':'icon-down-arrow'}`}></span>
        </div>
        {isOpen && <div className="language-select-component-items">
            <div className="language-select-component-item" onClick={() => setLangInternal("en", props.labels.LANGUAGE.ENG)}><span className="en-flag"></span><span>{getLangDesc(props.labels.LANGUAGE.ENG)}</span></div>
            <div className="language-select-component-item" onClick={() => setLangInternal("de", props.labels.LANGUAGE.DE)}><span className="de-flag"></span><span>{getLangDesc(props.labels.LANGUAGE.DE)}</span></div>
        </div>}
    </div>;
}