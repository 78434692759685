import { WidgetComponent } from '@flexfront/ui/react';
import { useRef} from 'react';
import './this-is-me-component.scss';
import { ErrorMessageComponent } from '../../../common/components/error-message-component/error-message-component';
import Dropdown, { } from 'react-dropdown';
import ReactDropdown from 'react-dropdown';
import { usePersonalInfoContext } from '../../../common/providers/personal-info-provider';
import ThisIsMeCompactComponent from '../this-is-me-component-compact/this-is-me-component-compact-component';

export function ThisIsMeComponent() {

  const genderRef = useRef<ReactDropdown>(null);

  const {
    labels,
    isHorizontallyCompact,
    isNameHidden,
    nameTabindex,
    name,
    handleNameChange,
    handleNameBlur,
    genderTabindex,
    gender,
    handleGenderChange,
    handleDropdownFocus,
    genderOptions,
    allowAgeInput,
    ageTabIndex,
    MIN_AGE,
    MAX_AGE,
    age,
    handleAgeChange,
    handleAgeBlur,
    hasError,
    setAllowAgeInput,
    thisIsMeIcon,
    opendropdown,
    setopendropdown,
    ageArray,
    handleAgeDropdownChange
  } = usePersonalInfoContext();

  return (
    <>
      {isHorizontallyCompact ? (
        <ThisIsMeCompactComponent />
      ) : (
        <WidgetComponent isExpanded={false} className="this-is-me-component">
          <div className="widget-title-container">
            {thisIsMeIcon && <span className="widget-title-icon"
              dangerouslySetInnerHTML={{ __html: thisIsMeIcon ?? '' }}></span>}
            <h3 className="widget-title">
              {labels.TITLE}
            </h3>
          </div>
          <div className="personal-component">
          {!isNameHidden && <div className="field-group">
              <label className="field-label">{labels.PERSONAL.NAME}:</label>
              <input
                inputMode="numeric"
                tabIndex={nameTabindex}
                className="field-value"
                type="text"
                value={name}
                placeholder={labels.PERSONAL.NAME_PLACEHOLDER}
                onChange={handleNameChange}
                onBlur={handleNameBlur}
              />
            </div>}
            <div className="field-group">
              <label className="field-label field-label-dropdown">{labels.PERSONAL.GENDER}:</label>
              <Dropdown
                ref={genderRef}
                arrowClosed={<span  tabIndex={genderTabindex} className="icon-down-arrow" />}
                arrowOpen={<span className="icon-up-arrow" />}
                baseClassName="Dropdown"
                value={gender}
                onChange={handleGenderChange}
                onFocus={() => handleDropdownFocus(genderRef)}
                options={genderOptions}
              />
            </div>
            <div className="field-group">
              <label className="field-label field-label-dropdown">{labels.PERSONAL.AGE}:</label>
              <div className={`age-input ${hasError ? 'error error-border' : ''}`}>
                {allowAgeInput?
                  <input
                  tabIndex={ageTabIndex}
                  className="field-value"
                  type="text"
                  min={MIN_AGE}
                  max={MAX_AGE}
                  value={age==0?'':age}
                  onChange={handleAgeChange}
                  onBlur={handleAgeBlur}
                />
                :
                <div className={`Dropdown-root ${opendropdown ? 'is-open' : ''}`} tabIndex={ageTabIndex}>
                <div className="Dropdown-control" aria-haspopup="listbox">
                    <div className="Dropdown-placeholder is-selected" onClick={() => (setAllowAgeInput(!allowAgeInput))}>{age==0?'':age}</div>
                    <div className="Dropdown-arrow-wrapper" onClick={() => (setopendropdown(!opendropdown))}><span className={opendropdown ? 'icon-up-arrow' : 'icon-down-arrow'}></span></div>
                </div>
                {opendropdown?
                <div className="Dropdown-menu" aria-expanded="true">
                    {ageArray.map((value, index)=>(
                        <div key={index} className="Dropdown-option" role="option" aria-selected="false" aria-valuenow={value} onClick={()=>handleAgeDropdownChange(value)}>{value}</div>
                    ))}
                     </div>
                : ''}
                 </div>
                }
              
              </div>
              <span className="error">{hasError ? <label className="icon-error"></label> : ''} </span>
            </div>
          </div>
        </WidgetComponent>
      )}
    </>
  );
}

export default ThisIsMeComponent;
