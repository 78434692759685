import { useEffect } from 'react';
import { useIsMounted, useLocalStorage } from 'usehooks-ts';
import { useAppDispatch, useAppSelector } from '../../../common/store/hooks';
import { addWarningMessage, removeWarningMessage } from '../../../common/store/notifications/notifications.slice';
import { NotificationType } from '../../../common/store/notifications/notifications.state';
import { useLabelsContext } from '../../../common/providers/labels-provider';
import { selectAssetsState } from '../../../common/store/assets/assets.slice';

export const useConfigChartNotification = (message: string) => {
  const dispatch = useAppDispatch();
  const isMounted = useIsMounted();
  const assets = useAppSelector(selectAssetsState);
  const [visits, setVisits] = useLocalStorage('visits', 0);
  const {
      labels,
  } = useLabelsContext();

  useEffect(() => {
    if (isMounted() && visits <= 3) {
      setVisits(visits + 1);
    }
  }, [isMounted]);

  useEffect(() => {
    if (visits <= 1 && (assets.cash===0 && assets.bonds===0  && assets.equity===0  && assets.realEstate===0)) {
      dispatch(addWarningMessage({type: NotificationType.CONFIG_CHART, message: message}));
    } else if (visits <= 3 && (assets.cash===0  && assets.bonds===0  && assets.equity===0 && assets.realEstate===0)) {
      dispatch(addWarningMessage({type: NotificationType.CONFIG_CHART, message: message}));
      setTimeout(() => {
        dispatch(removeWarningMessage(NotificationType.CONFIG_CHART));
      }, 10000);
    }else {
      dispatch(removeWarningMessage(NotificationType.CONFIG_CHART));
    }
  }, [visits,labels,assets,message]);
};
