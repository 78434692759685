import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from "@reduxjs/toolkit";
import { environment } from "../../../../environments/environment";
import { AppDispatch, RootState } from "../store";
import { ComponentIconConfig, ComponentOrderConfig, ComponentVisibilityConfig, TenantColorConfig, TenantConfigAllState, TenantConfigState } from "./tenant-config.state";
import { setUserSettingsLang } from "../user-settings.slice";

export const TENANT_CONFIG_FEATURE_KEY = "tenantConfig";

const selectedTenantConfig: TenantConfigState = {
  templateId: -1,
  isActive: true,
  name: "",
  companyName: "",
  address: "",
  postalCodeAndCity: "",
  website: "",
  logo: "",
  defaultLang: undefined,
  currency: "EUR",
  inflation: "enable_per_individual_goal",
  isClimateScenarioHidden: false,
  isReportDownloadEnabled: false,
  thisIsMeMobileLogo: "",
  mobileLogo: "",
  reportLogo: "",
  colors: [
    {
      name: "white",
      value: "#FFFFFF",
    },
    {
      name: "black",
      value: "#000000",
    },
    {
      name: "primaryInput",
      value: "#323655",
    },
    {
      name: "primaryRetirementPlotband",
      value: "#E4E6F7",
    },
    {
      name: "primaryWidget0",
      value: "rgb(48, 55, 92)",
    },
    {
      name: "primaryWidget1",
      value: "rgb(35, 40, 73, 0.95)",
    },
    {
      name: "primaryButton",
      value: "#0E122F",
    },
    {
      name: "secondary0",
      value: "rgb(48, 55, 92)",
    },
    {
      name: "secondary1",
      value: "rgb(48, 55, 92)",
    },
    {
      name: "accentInput",
      value: "#4a90ed",
    },
    {
      name: "accentAsset",
      value: "#8BABF1",
    },
    {
      name: "accentLeverage",
      value: "#646783",
    },
    {
      name: "accentSnapshot",
      value: "#666666",
    },
    {
      name: "warn",
      value: "#ef6357",
    },
    {
      name: "chartIcons",
      value: "",
    },
    {
      name: "legacyIcons",
      value: "",
    },
    {
      name: "dreamIcons",
      value: "",
    },
    {
      name: "capitalPreservationIcons",
      value: "",
    },
    {
      name: "secureMyFamilyIcons",
      value: "",
    },
    {
      name: "header",
      value: "",
    },
    {
      name: "primaryBorder0",
      value: "",
    },
    {
      name: "secondaryBorder1",
      value: "",
    },
    {
      name: "secondaryButton",
      value: "",
    },
    {
      name: "emailBackground",
      value: "",
    },
    {
      name: "inform",
      value: "",
    },
    {
      name: "positive",
      value: "",
    },
    {
      name: "landingPageText",
      value: "",
    },
    {
      name: "primaryButtonText",
      value: "",
    },
    {
      name: "secondaryButtonText",
      value: "",
    },
    {
      name: "circularChartArea",
      value: "",
    },
    {
      name: "circularChartComponentBackground",
      value: "",
    },
    {
      name: "icardBackground",
      value: "",
    },
    {
      name: "menuBackground",
      value: "",
    },
    {
      name: "infoIconBackground",
      value: "",
    },
    {
      name: "infoIconBorder",
      value: "",
    },
    {
      name: "sliderDotsBackground",
      value: "",
    },
    {
      name: "headerText",
      value: "",
    },
    {
      name: "headerText1",
      value: "",
    },
    {
      name: "dropdownText",
      value: "",
    },
    {
      name: "menuText",
      value: "",
    },
    {
      name: "legendText",
      value: "",
    },
    {
      name: "nudgeBorder",
      value: "",
    },
    {
      name: "legendBorder",
      value: "",
    },
    {
      name: "menuBorder",
      value: "",
    },
    {
      name: "menuIcon",
      value: "",
    },
    {
      name: "deactiveTabBackground",
      value: "",
    },
    {
      name: "deactiveTabText",
      value: "",
    },
    {
      name: "backgroundForMobile",
      value: "",
    },
    {
      name: "secondaryBackgroundForMobile",
      value: "",
    },
    {
      name: "editWindowBackground",
      value: "",
    },
    {
      name: "labelColor",
      value: "",
    },
    {
      name: "appBackground",
      value: "",
    },
    {
      name: "nudgeBackground",
      value: "",
    },
    {
      name: "disclaimerText",
      value: "",
    },
  ],
  isLoading: true,
  layoutId: 1,
  layout: {
    layoutId: 1,
    name: "Default",
    componentLayouts: [],
    componentIcons: [],
    componentOrder: [],
    domainLayouts: []
  },
  apiKey: "",
  scenarioSpaceAndVersion: ""
};

const initialState: TenantConfigAllState = {
  isAddingNewItem: false,
  selectedTenantConfig,
  allTenantConfigs: [],
  isLoading: true
};

export const tenantConfigSlice = createSlice({
  name: TENANT_CONFIG_FEATURE_KEY,
  initialState,
  reducers: {
    setTenantConfigAll: (state, action: PayloadAction<TenantConfigState[]>) => {
      return {
        ...state,
        allTenantConfigs: action.payload,
        selectedTenantConfig: action.payload[0]
      };
    },
    setTenantGuid: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        selectedTenantConfig: {
          ...state.selectedTenantConfig,
          name: action.payload
        }
      };
    },
    setTenantConfig: (state, action: PayloadAction<TenantConfigState>) => {
      const allTenantConfigs = state.allTenantConfigs.map((c, i) => {
        if (c.templateId === action.payload.templateId) {
          return action.payload;
        }
        return c;
      });

      return {
        ...state,
        isAddingNewItem: action.payload.templateId === 0,
        selectedTenantConfig: action.payload,
        allTenantConfigs: allTenantConfigs
      };
    },
    setTenantConfigWithNewItem: (state, action: PayloadAction<TenantConfigState>) => {
      const allTenantConfigs = state.allTenantConfigs.map((c, i) => {
        if (i === 0) {
          return action.payload;
        }
        return c;
      });

      return {
        ...state,
        isAddingNewItem: false,
        selectedTenantConfig: action.payload,
        allTenantConfigs: allTenantConfigs
      };
    },
    setTenantColors: (state, action: PayloadAction<TenantColorConfig[]>) => {
      return {
        ...state,
        selectedTenantConfig: {
          ...state.selectedTenantConfig,
          colors: action.payload
        }
      };
    },
    addTenantConfig: (state, action: PayloadAction<TenantConfigState>) => {
      return {
        ...state,
        allTenantConfigs: [action.payload, ...state.allTenantConfigs],
        selectedTenantConfig: action.payload,
        isAddingNewItem: true
      };
    },
    setTenantConfigDefaultLang: (state, action: PayloadAction<string | undefined>) => {
      return {
        ...state,
        selectedTenantConfig: {
          ...state.selectedTenantConfig,
          defaultLang: action.payload
        }
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTenantConfigAll.pending, (state) => {
        state.isLoading = true;
        return state;
      })
      .addCase(fetchTenantConfigAll.fulfilled, (state) => {
        state.isLoading = false;
        return state;
      })
      .addCase(fetchTenantConfig.pending, (state) => {
        state.selectedTenantConfig.isLoading = true;
        return state;
      })
      .addCase(fetchTenantConfig.fulfilled, (state) => {
        state.selectedTenantConfig.isLoading = false;
        return state;
      })
      .addCase(fetchTenantConfigByDomain.pending, (state) => {
        state.selectedTenantConfig.isLoading = true;
        return state;
      })
      .addCase(fetchTenantConfigByDomain.fulfilled, (state) => {
        state.selectedTenantConfig.isLoading = false;
        return state;
      })
      .addCase(updateTenantConfig.pending, (state) => {
        state.selectedTenantConfig.isLoading = true;
        return state;
      })
      .addCase(updateTenantConfig.fulfilled, (state) => {
        state.selectedTenantConfig.isLoading = false;
        return state;
      });
  },
});

export const fetchTenantConfig = createAsyncThunk<
  void,
  {
    name: string | undefined,
    abortSignal: AbortSignal
  }>(
  "tenant/fetchTenantConfig",
  async (args, thunkApi: any) => {
    let configUrl = `${environment.apiBaseUrl}/config/`;
    configUrl = configUrl + (args.name || "default");
    return fetch(configUrl, {
      signal: args.abortSignal
    }).then((result) => {
      if (result.ok) {
        result.json().then((json) => {
          const tenantConfig = json as TenantConfigState;
          if (!tenantConfig.colors || tenantConfig.colors.length === 0) {
            tenantConfig.colors = selectedTenantConfig.colors;
          }
          const state = thunkApi.getState();
          if (tenantConfig.templateId !== 0 && !state.userSettings.lang) {
            thunkApi.dispatch(setUserSettingsLang(tenantConfig.defaultLang));
          }
          return thunkApi.dispatch(setTenantConfig(tenantConfig));
        });
      }
    });
  }
);

export const fetchTenantConfigByDomain = createAsyncThunk<
  void,
  {
    domain: string | undefined,
    abortSignal: AbortSignal
  }>(
  "tenant/fetchTenantConfigByDomain",
  async (args, thunkApi: any) => {
    let configUrl = `${environment.apiBaseUrl}/config/domain/`;
    configUrl = configUrl + (args.domain || "default");
    return fetch(configUrl, {
      signal: args.abortSignal
    }).then((result) => {
      if (result.ok) {
        return result.json().then((json) => {
          const tenantConfig = json as TenantConfigState;
          if (!tenantConfig.colors || tenantConfig.colors.length === 0) {
            tenantConfig.colors = selectedTenantConfig.colors;
          }
          const state = thunkApi.getState();
          if (tenantConfig.templateId !== 0 && !state.userSettings.lang) {
            thunkApi.dispatch(setUserSettingsLang(tenantConfig.defaultLang));
          }
          return thunkApi.dispatch(setTenantConfig(tenantConfig));
        });
      }
      throw new Error(result.status.toString());
    });
  }
);

export const fetchTenantConfigAll = createAsyncThunk<void, AbortSignal>(
  "tenant/fetchTenantConfigAll",
  async (abortSignal, thunkApi) => {
    const configUrl = `${environment.apiBaseUrl}/config`;
    return fetch(configUrl, {
      signal: abortSignal
    }).then((result) => {
      if (result.ok) {
        result.json().then((json) => {
          thunkApi.dispatch(setTenantConfigAll(json as TenantConfigState[]));
        });
      }
    });
  }
);

export const addConfig = createAsyncThunk(
  "tenant/addConfig",
  async (_, thunkApi) => {
    const configUrl = `${environment.apiBaseUrl}/config/getNew`

    return fetch(configUrl).then((result) => {
      if (result.ok) {
        result.json().then((json) => {
          thunkApi.dispatch(addTenantConfig(json as TenantConfigState));
        });
      }
    });
  }
);

export const deleteConfig = createAsyncThunk<
  void,
  TenantConfigState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "tenant/deleteConfig",
  async (tenantConfig: TenantConfigState | undefined, thunkApi) => {
    const state: RootState = thunkApi.getState();
    const configUrl = `${environment.apiBaseUrl}/config/${tenantConfig?.templateId}`;
    return fetch(configUrl, {method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${state.auth.token}`,
    },}).then((result) => {
      if (result.ok) {
        result.json().then((json) => {
          thunkApi.dispatch(setTenantConfigAll(json as TenantConfigState[]));
        });
      }
    });
  }
);

export const updateTenantConfig = createAsyncThunk<
  void,
  TenantConfigState,
  {
    dispatch: AppDispatch;
    state: RootState;
  }
>(
  "tenant/updateTenantConfig",
  async (tenantConfig: TenantConfigState, thunkApi) => {
    const state: RootState = thunkApi.getState();
    let configUrl = `${environment.apiBaseUrl}/config/`;
    configUrl = configUrl + (tenantConfig.name || "default");
    return fetch(configUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${state.auth.token}`,
      },
      body: JSON.stringify(tenantConfig),
    }).then((result) => {
      if (result.ok) {
        result.json().then((json) => {
          if (state.tenantConfig.isAddingNewItem) {
            thunkApi.dispatch(setTenantConfigWithNewItem(json as TenantConfigState));
          } else {
            thunkApi.dispatch(setTenantConfig(json as TenantConfigState));
          }
        });
      }
    });
  }
);

export const fetchTenantLayoutConfig = createAsyncThunk(
  "tenant/fetchLayoutConfig",
  async (layoutId: number, thunkApi) => {
    const configUrl = `${environment.apiBaseUrl}/layouts/${layoutId}`;
    return fetch(configUrl).then((result) => {
      if (result.ok) {
        const state = thunkApi.getState() as RootState;
        result.json().then((json) => {
          thunkApi.dispatch(setTenantConfig({
              ...state.tenantConfig.selectedTenantConfig,
              layoutId: layoutId,
              layout: {
                ...state.tenantConfig.selectedTenantConfig.layout,
                layoutId: layoutId,
                componentLayouts: json as ComponentVisibilityConfig[],
                componentIcons: state.tenantConfig.selectedTenantConfig.layout.componentIcons ?? []
              }
            }));
        });
      }
    });
  }
);

export const fetchTenantIconConfig = createAsyncThunk(
  "tenant/fetchIconConfig",
  async (layoutId: number, thunkApi) => {
    const configUrl = `${environment.apiBaseUrl}/icons/${layoutId}`;
    return fetch(configUrl).then((result) => {
      if (result.ok) {
        const state = thunkApi.getState() as RootState;
        result.json().then((json) => {
          thunkApi.dispatch(setTenantConfig({
              ...state.tenantConfig.selectedTenantConfig,
              layoutId: layoutId,
              layout: {
                ...state.tenantConfig.selectedTenantConfig.layout,
                layoutId: layoutId,
                componentIcons: json as ComponentIconConfig[],
              }
            }));
        });
      }
    });
  }
);

export const fetchTenantOrderConfig = createAsyncThunk(
  "tenant/fetchOrderConfig",
  async (layoutId: number, thunkApi) => {
    const configUrl = `${environment.apiBaseUrl}/order/${layoutId}`;
    return fetch(configUrl).then((result) => {
      if (result.ok) {
        const state = thunkApi.getState() as RootState;
        result.json().then((json) => {
          thunkApi.dispatch(setTenantConfig({
              ...state.tenantConfig.selectedTenantConfig,
              layoutId: layoutId,
              layout: {
                ...state.tenantConfig.selectedTenantConfig.layout,
                layoutId: layoutId,
                componentOrder: json as ComponentOrderConfig[],
              }
            }));
        });
      }
    });
  }
);

export const { setTenantConfigAll, setTenantConfig, setTenantConfigWithNewItem, setTenantGuid, setTenantColors, addTenantConfig , setTenantConfigDefaultLang  } = tenantConfigSlice.actions;

export const selectTenantConfigAll = createSelector(
  (state: RootState) => state.tenantConfig,
  (tenantConfig) => tenantConfig
);

export const selectTenantConfig = createSelector(
  (state: RootState) => state.tenantConfig.selectedTenantConfig,
  (tenantConfig) => tenantConfig
);

export const selectTenantConfigColors = createSelector(
  (state: RootState) => state.tenantConfig.selectedTenantConfig.colors,
  (tenantColorConfig: TenantColorConfig[]) =>
    getIndexedColors(tenantColorConfig)
);

export const selectTenantConfigDefaultLang = createSelector(
  (state: RootState) => state.tenantConfig.selectedTenantConfig.defaultLang,
  (defaultLang) => defaultLang
);

export const selectTenantConfigInflation = createSelector(
  (state: RootState) => state.tenantConfig.selectedTenantConfig.inflation,
  (inflation) => inflation
);

function getIndexedColors(colors: TenantColorConfig[]): Map<string, string> {
  const indexedColors = new Map<string, string>();

  colors.forEach((color) => {
    indexedColors.set(color.name, color.value);
  });

  return indexedColors;
}

export default tenantConfigSlice;
