import "./app.scss";
import { Route, Routes } from "react-router-dom";
import { ErrorBoundaryComponent } from "@flexfront/ui/react";
import { useDebug } from "./common/hooks/useDebug";
import { ProtectedRoute, ProtectedRouteProps } from "./common/guards/protected-route";
import { LoginComponent } from "./common/components/login-component/login-component";
import { useAppSelector } from "./common/store/hooks";
import { selectAuth } from "./common/store/auth/auth.slice";
import { EnsureTenantConfig } from "./common/guards/ensure-tenant-config";
import { useMatomo } from "@jonkoops/matomo-tracker-react";
import { useIsFirstRender } from "usehooks-ts";
import { DashboardSelectorComponent } from "./common/components/dashboard-selector-component/dashboard-selector-component";
import { ReportComponent } from "./default/components/report-component/report-component";
import { DashboardProvider } from "./common/providers/dashboard-provider";
import { IFrameComponent } from "./default/components/iframe-component/iframe-component";
import NotFoundComponent from "./common/components/not-found-component/not-found-component";
import { LabelsProvider } from "./common/providers/labels-provider";

export function App() {
  useDebug();
  
  const auth = useAppSelector(selectAuth);

  const { enableLinkTracking, trackPageView } = useMatomo();

  enableLinkTracking();

  if (useIsFirstRender()) {
    trackPageView();
  }

  const dashboard = <main className="main">
    <EnsureTenantConfig>
    <LabelsProvider>
        <DashboardSelectorComponent />
      </LabelsProvider>
    </EnsureTenantConfig>
  </main>;

  const report = <main className="main">
    <EnsureTenantConfig>
      <LabelsProvider>
        <DashboardProvider
          disableShortfallEliminationCall={true}>
          <ReportComponent />
        </DashboardProvider>
      </LabelsProvider>
    </EnsureTenantConfig>
  </main>;

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: auth.isLoggedIn,
    authenticationPath: "login",
  };

  return (
    <ErrorBoundaryComponent>
        <Routes>
          <Route path="/">
            <Route path="" element={dashboard} />
            <Route path=":name" element={dashboard} />
            <Route path=":name/iframe" element={<IFrameComponent />} />
            <Route path=":name/report/:state" element={report} />
            <Route path="/persona/:id" element={dashboard} />
            <Route path=":name/persona/:id" element={dashboard} />
            <Route
              path=":name/config"
              element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={dashboard} />}
            />
            <Route path="/config" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={dashboard} />} />
            <Route path="/list" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={dashboard} />} />
          </Route>
          <Route path="/config/login" element={<LoginComponent />} />
          <Route path="/list/login" element={<LoginComponent />} />
          <Route path="/:name/config/login" element={<LoginComponent />} />
          <Route path="*" element={<EnsureTenantConfig>
              <LabelsProvider>
                <NotFoundComponent />
              </LabelsProvider>
            </EnsureTenantConfig>} />
        </Routes>
    </ErrorBoundaryComponent>
  );
}

export default App;
