import { ReactNode, createContext, useContext, useEffect, useRef, useState } from "react";
import { RetirementChartComponentLabels } from "../labels/retirement-chart-component.labels";
import { SimulationState } from "../store/simulation/simulation.state";
import Highcharts from "highcharts";
import draggable from 'highcharts/modules/draggable-points';
import highchartsMore from "highcharts/highcharts-more";
import { enUS, de } from "date-fns/locale";
import { useQuery } from "../hooks/useQuery";
import { useDeviceBounds } from "../hooks/useDeviceBounds";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectPersonalState } from "../store/personal.slice";
import { saveRetirement, selectGoalsState, selectRetirementState } from "../store/goals/goals.slice";
import { selectSnapshotsState } from "../store/snapshots/snapshots.slice";
import { selectAssetsState } from "../store/assets/assets.slice";
import { selectUserSettingsState } from "../store/user-settings.slice";
import { useCurrencyConfig } from "../hooks/useCurrencyConfig";
import { getInitialChartOptions } from "../../default/components/retirement-chart-component/getInitialChartOptions";
import { useWindowSize } from "usehooks-ts";
import HighchartsReact from 'highcharts-react-official';
import { addWarningMessage, removeWarningMessage } from "../store/notifications/notifications.slice";
import { NotificationType } from "../store/notifications/notifications.state";
import { getTimelineSeries } from "../../default/components/retirement-chart-component/getTimelineSeries";
import { getTotalWealthSeries } from "../../default/components/retirement-chart-component/getTotalWealthSeries";
import { getIlliquidSeries } from "../../default/components/retirement-chart-component/getIlliquidSeries";
import { getRetirementSeries } from "../../default/components/retirement-chart-component/getRetirementSeries";
import {
    setTimelineWidthPerQuarter,
    setTimelineWidth as setExternalTimelineWidth,
    setXOffset
  } from '../store/timeline/timeline.slice';
import { getQuarterDate } from "@flexfront/ui/react";
import { getYAxisLabelFormatter } from "../../default/components/retirement-chart-component/getYAxisLabelFormatter";
import { getRetirementPlotBands } from "../../default/components/retirement-chart-component/getRetirementPlotBands";
import { getTooltipOptions } from "../../default/components/retirement-chart-component/getTooltipOptions";
import _ from "lodash";
import { useTenantLogo } from "../hooks/useTenantLogo";
import { useComponentIcon } from "../hooks/useComponentIcon";

export interface ChartContextProps {
    children: ReactNode;
    labels: RetirementChartComponentLabels;
    simulationState: SimulationState;
    showTooltip: boolean;
    onRequiresUpdate?: () => void;
    onRendered?: () => void;
    heightOverride?: number;
    widthOverride?: number;
    className?: string;
    animation?: boolean;
}

interface ChartFunctionality {
    labels: RetirementChartComponentLabels;
    onRequiresUpdate?: () => void;
    onRendered?: () => void;
    simulationState: SimulationState;
    showTooltip: boolean;
    heightOverride?: number;
    widthOverride?: number;
    loaderColor: string;
    chartOptions: Highcharts.Options;
    Highcharts: typeof Highcharts;
    chartRef: React.RefObject<HighchartsReact.RefObject>;
    containerProps: any;
    setContainerProps: React.Dispatch<any>;
    height: number;
    width: number;
    isTabletLandscape: boolean;
    isTabletPortrait: boolean;
    isHorizontallyCompact: boolean;
    isLogoAvailable: boolean;
}

const ChartContext = createContext<ChartFunctionality | undefined>(undefined);

export const useChartContext = (): ChartFunctionality => {
    const context = useContext(ChartContext);
    if (!context) {
        throw new Error('useChartHeaderContext must be used within a ChartHeaderProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(ChartContext)!;
};

type LangLocaleMapping = {
    [key:string]: Locale;
}

export const ChartProvider: React.FC<ChartContextProps> = (props: ChartContextProps) => {

    if (typeof Highcharts === 'object') {
    highchartsMore(Highcharts);
    draggable(Highcharts);
  }
  const langLocaleMapping:LangLocaleMapping = {
    "ENG": enUS,
    "EN": enUS,
    "DE": de
};

  const userSettings = useAppSelector(selectUserSettingsState);
  const langParam = (userSettings.lang || "en").toUpperCase();
  const locale = langLocaleMapping[langParam];
  
  const { isHorizontallyCompact, isTabletLandscape, isTabletPortrait } = useDeviceBounds();

  const personal = useAppSelector(selectPersonalState);
  const goals = useAppSelector(selectGoalsState);
  const [simulationState, setSimulationState] = useState<SimulationState>(props.simulationState);
  const snapshots = useAppSelector(selectSnapshotsState);
  const retirement = useAppSelector(selectRetirementState);
  const assets = useAppSelector(selectAssetsState);
  const dispatch = useAppDispatch();

  const currencyConfig = useCurrencyConfig();
  const [retirementSeriesY, setRetirementSeriesY] = useState<number>(50);
  const [chartOptions, setChartOptions] = useState<Highcharts.Options>(getInitialChartOptions(isHorizontallyCompact,props.showTooltip, currencyConfig));

  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const { width, height } = useWindowSize();
  const [containerProps, setContainerProps] = useState<any>();
  const tenantLogo = useTenantLogo();
  const [isLogoAvailable, setIsLogoAvailable] = useState<boolean>(false);
  const loaderColor = getComputedStyle(document.documentElement).getPropertyValue('--secondary-0');
  const mortgageIcon = useComponentIcon("I_HAVE_LIABILITY_ICON");

  useEffect(() => {
    const hasValues = simulationState.totalWealth.percentile50.filter((entry) => entry > 0).length > 0;
    const hasIlliquid = simulationState.illiquid.percentile50.filter((entry) => entry > 0).length > 0;

    const maxValue =
      simulationState.totalWealth.percentile75.length > 0
        ? Math.max.apply(null, simulationState.totalWealth.percentile75)
        : 1;
    const leverageQuarter = hasIlliquid ? simulationState.leverageQuarter : 0;

    function GetInfoWithIcon(message: string) {
      if (mortgageIcon) {
        return props.labels.MORTGAGE_INFO.replace("<div class='icon-leverage'></div>", `<div class='icon'>${mortgageIcon}</div>`)
      }
      
      return props.labels.MORTGAGE_INFO;
    }

    if (userSettings.includeIlliquid  && simulationState.leverageQuarter <= simulationState.totalQuarters && simulationState.leverageQuarter > 0 && assets.realEstate > 0){
      dispatch(
        addWarningMessage({
          type: NotificationType.MORTGAGE_INFO,
          message: GetInfoWithIcon(props.labels.MORTGAGE_INFO),
        })
      );
    } else {
      dispatch(
        removeWarningMessage(NotificationType.MORTGAGE_INFO)
      );
    }
    
    const timelineSeries = getTimelineSeries(simulationState.totalQuarters);
    
    const totalWealthSeries = getTotalWealthSeries(
      simulationState.totalWealth,
      leverageQuarter,
      simulationState.ageAtQuarter,
      userSettings.includePrevious,
      isHorizontallyCompact,
      snapshots.totalWealthSnapshot
    );

    const illiquidSeries = getIlliquidSeries(
      simulationState.illiquid,
      userSettings.includeIlliquid,
      isHorizontallyCompact,
      props.animation);

    const retirementSeries = getRetirementSeries(
      retirementSeriesY,
      `${props.labels.RETIREMENT_TITLE} ${goals.retirement.retirementAge}`,
      simulationState.totalQuarters,
      simulationState.activeQuarters,
      isHorizontallyCompact,
      retirement.retirementStartDateUtc,
      props.animation
    );

    const series: Highcharts.SeriesOptionsType[] = timelineSeries
      .concat(totalWealthSeries)
      .concat(illiquidSeries)
      .concat(retirementSeries);

    function trySetTimelineWidth(elementClassName: string) {
      const elements = document.getElementsByClassName(elementClassName);
      if (elements.length > 0) {
        const elementWidth = elements[0].getBoundingClientRect().width;
        if (elementWidth > 0) {
          dispatch(setExternalTimelineWidth(elementWidth));
          return true;
        }
      }
      return false;
    }

    function getSeries(seriesName: string, chart?: Highcharts.Chart): Highcharts.Series | undefined {
      let seriesToFind;
      if (chart && chart.series.length > 0) {
        seriesToFind = chart.series.find(s => s.name === seriesName);
      }
      return seriesToFind;
    }

    function hasPoints(series?: Highcharts.Series): boolean | undefined {
      return series && series.points && series.points.length > 0;
    }

    setChartOptions({
      chart: {
        ...chartOptions.chart,
        className: props.className,
        events: {
          render: function () {

            if (!trySetTimelineWidth('total-wealth-good-range-series')) {
              trySetTimelineWidth('highcharts-plot-background');
            }

            const totalWealthExpectedSeries = getSeries('totalWealthExpectedSeries', this);
            if (hasPoints(totalWealthExpectedSeries) && props.onRendered) {
              props.onRendered();
            }
            
          },
        },
      },
      plotOptions: {
        series: {
          point: {
            events: {
              drop: function (e: any) {
                const newRetirementDate = getQuarterDate(new Date(e.newPoint.x));
                const currentDate = getQuarterDate(new Date());

                const newRetirementAge = personal.age + (newRetirementDate.getFullYear() - currentDate.getFullYear());
                const newRetirementDateUtc = Date.UTC(newRetirementDate.getFullYear(), newRetirementDate.getMonth(), 1);

                dispatch(
                  saveRetirement({
                    ...retirement,
                    retirementAge: newRetirementAge,
                    retirementStartDateUtc: newRetirementDateUtc,
                  })
                );
                if (props.onRequiresUpdate) props.onRequiresUpdate();
              },
            },
          },
        },
      },
      title: {
        text: undefined,
      },
      yAxis: {
        visible: hasValues,
        ceiling: maxValue,
        labels: {
          formatter: getYAxisLabelFormatter(isHorizontallyCompact, currencyConfig)
        },
        events: {
          afterSetExtremes: function() {
            if (this.tickPositions && this.tickPositions.length > 0) {
              const positions = isHorizontallyCompact ? 3 : 2;
              setRetirementSeriesY(this.tickPositions[1] / positions);
            }
          }
        }
      },
      xAxis: {
        plotBands: [
          ...getRetirementPlotBands(
            `${props.labels.RETIREMENT_TITLE} ${goals.retirement.retirementAge}`,
            simulationState.totalQuarters,
            simulationState.activeQuarters,
            retirement.retirementStartDateUtc
          ),
        ]
      },
      tooltip: getTooltipOptions(
        props.labels.TOOLTIP,
        locale,
        isHorizontallyCompact,
        currencyConfig
      ),
      series: series,
    });
  }, [props.labels.RETIREMENT_TITLE, props.labels.TOOLTIP, simulationState, goals, snapshots, retirementSeriesY, currencyConfig, userSettings]);

  useEffect(() => {
    if (chartRef.current) {
      dispatch(setXOffset(chartRef.current.chart.plotLeft));
      dispatch(setExternalTimelineWidth(chartRef.current.chart.plotWidth));
      if (chartRef.current.chart.plotWidth > 0 && simulationState.totalQuarters > 0) {
        dispatch(setTimelineWidthPerQuarter(chartRef.current.chart.plotWidth / simulationState.totalQuarters));
      }
    }
  }, [chartOptions, simulationState, width, dispatch, containerProps]);

  useEffect(() => {
    setSimulationState(props.simulationState);
  }, [props.simulationState]);

  useEffect(() => {
    setIsLogoAvailable(tenantLogo !== undefined);
 }, [tenantLogo]);

    const contextValue = {
        children: props.children,
        labels: props.labels,
        simulationState: props.simulationState,
        showTooltip: props.showTooltip,
        heightOverride: props.heightOverride,
        widthOverride: props.widthOverride,
        onRequiresUpdate: props.onRequiresUpdate,
        onRendered: props.onRendered,
        loaderColor: loaderColor,
        chartOptions: chartOptions,
        Highcharts: Highcharts,
        chartRef: chartRef,
        containerProps: containerProps,
        setContainerProps: setContainerProps,
        isTabletLandscape: isTabletLandscape,
        isTabletPortrait: isTabletPortrait,
        isHorizontallyCompact: isHorizontallyCompact,
        width: width,
        height: height,
        isLogoAvailable: isLogoAvailable
    };

    return (
        <ChartContext.Provider value={contextValue}>{props.children}</ChartContext.Provider>
      );
};
