import './retirement-chart-header-component.scss';
import { useChartHeaderContext } from '../../../common/providers/chart-header-provider';
import { InflationOptions } from '@flexfront/models';
import { ModalSelectionComponent } from '../../../default/components/retirement-chart-header-component/modal-selection-component';
import { Fragment } from 'react';
import { MenuComponent } from '../../../default/components/menu-component/menu-component';
import { InflationModalComponent } from '../../../default/components/inflation-modal-component/inflation-modal-component';
import { ClimateScenarioModalComponent } from '../../../default/components/climate-scenario-modal-component/climate-scenario-modal-component';


export function RetirementChartHeaderComponent() {

  const {
    labels,
    onRequiresUpdate,
    isInflationSelectionOpen,
    openInfoModal,
    isClimateScenarioHidden,
    getSelectedClimateScenario,
    isClimateSelectionOpen,
    openClimateSelectionModal,
    setIsClimateSelectionOpen,
    setIsInflationSelectionOpen,
    tenantConfig,
    openInflationSelectionModal,
    isInflationToggleSelected,
    setisInflationToggleSelected
  } = useChartHeaderContext();

  function renderInflation(option: any){
      if(option == InflationOptions.disable){
        return;
      }
      else if(option === InflationOptions.enable_for_all_goals)
      {
          return (
            <div className="quirion-retirement-chart-inflation-section-toggle-component">
              <div>{labels.INFLATION.TITLE}</div>
              <div className="toggle-button">
                <label className="switch">
                    <input
                        type="checkbox"
                        checked={isInflationToggleSelected}
                        onChange={() => {setisInflationToggleSelected(!isInflationToggleSelected)}}
                    />
                    <span className="toggle round"></span>
                </label>
              </div>
            </div>);
      }
      else{
          return (<ModalSelectionComponent
            selectedLabel={`${labels.INFLATION.TITLE} ${labels.INFLATION.OPEN_BUTTON}`}
            isOpen={isInflationSelectionOpen}
            onClick={openInflationSelectionModal}
            />);
      }
    }

  return (
    <Fragment>
      <div className="quirion-retirement-chart-info">
        <div className="quirion-retirement-chart-info-logo">
          <div className="icon-info info-icon" onClick={openInfoModal}></div>
          <label className="quirion-widget-title">{labels.TITLE}</label>
        </div>
        <div className="quirion-retirement-chart-inflation-section" style={{gridArea: `${isClimateScenarioHidden ? 'climate' : 'inflation'}`}}>
        {renderInflation(tenantConfig.inflation)}
        </div>
        {!isClimateScenarioHidden && <div className="quirion-retirement-chart-climate-scenario">
          <ModalSelectionComponent
            selectedLabel={`${labels.CLIMATE_SCENARIO.TITLE}: ${getSelectedClimateScenario()}`}
            isOpen={isClimateSelectionOpen}
            onClick={openClimateSelectionModal}
            />
        </div>}
        <div className="quirion-retirement-chart-menu">
          <MenuComponent labels={labels.MENU} />
        </div>
      </div>
      {isInflationSelectionOpen && (
        <InflationModalComponent
          labels={labels.INFLATION}
          onClose={() => setIsInflationSelectionOpen(false)}
          onRequiresUpdate={onRequiresUpdate}
        />
      )}
      {isClimateSelectionOpen && (
        <ClimateScenarioModalComponent
          labels={labels.CLIMATE_SCENARIO}
          onClose={() => setIsClimateSelectionOpen(false)}
          onRequiresUpdate={onRequiresUpdate}
        />
      )}
    </Fragment>
  );
}

export default RetirementChartHeaderComponent;
