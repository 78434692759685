import ThisIsMeComponent from "../this-is-me-component/this-is-me-component";
import { FlyoutMenuItemComponent } from "./flyout-menu-item-component";
import { FlyoutMenuComponent } from "./flyout-menu-component";
import LegendComponent from "../legend-component/legend-component";
import InfoComponent from "../info-component/info-component";
import { ResetComponent } from "../reset-component/reset-component";
import { LanguageSelectComponent } from "../language-select-component/language-select-component";
import { ShowPropertyComponent } from "../show-property-component/show-property-component";
import { ShowPreviousValuesComponent } from "../show-previous-values-component/show-previous-values-component";
import "./flyout-menu-wrapper-component.scss";
import { InflationOptions } from "@flexfront/models";
import { InflationMenuComponent } from "../inflation-menu-component/inflation-menu-component";
import { ClimateScenarioMenuComponent } from "../climate-scenario-menu-component/climate-scenario-menu-component";
import { PersonalInfoProvider } from "../../../common/providers/personal-info-provider";
import { DownloadReportComponent } from "../download-report-component/download-report-component";
import { ChartProvider } from "../../../common/providers/chart-provider";
import { RetirementChartComponent } from "../retirement-chart-component/retirement-chart-component";
import { useFlyoutMenuWrapperContext } from "../../../common/providers/flyout-menu-wrapper-provider";

export function FlyoutMenuWrapperComponent() {

    const {
        labels,
        setisInflationToggleSelected,
        isInflationToggleSelected,
        isOpen,
        setIsOpen,
        tenantLogo,
        toggle,
        tenantConfig,
        personal,
        getGenderDesc,
        ClimateScenarioName,
        staticSimulationState,
        userSettings,
        simulationState,
        isRealEstateHidden,
        isReportDownloading,
        onReportDowloading,
        onRequiresUpdate,
        close,
        confirm,
    } = useFlyoutMenuWrapperContext();

    function renderInflation(option: any){
        if(option == InflationOptions.disable){
          return;
        }
        else if(option === InflationOptions.enable_for_all_goals)
        {
            return (<FlyoutMenuItemComponent
                    content={<>
                        <span onClick={() => {setisInflationToggleSelected(!isInflationToggleSelected)}}>{labels.INFLATION_SELECTION.TITLE}</span>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={isInflationToggleSelected}
                                onChange={() => {setisInflationToggleSelected(!isInflationToggleSelected)}}
                            />
                            <span className="toggle round"></span>
                        </label>
                    </>}
                />);
        }
        else{
            return (<FlyoutMenuItemComponent
                title={labels.INFLATION_SELECTION?.TITLE}
                nestedContent={<InflationMenuComponent
                    labels={labels.INFLATION_SELECTION}
                    onRequiresUpdate={confirm}
                    onClose={close}
                />}
            />);
        }
    }
      
    

    return <FlyoutMenuComponent
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={labels.TITLE}
        header={<div className="flyout-menu-header">
            {tenantLogo && <img className="flyout-menu-logo" src={tenantLogo} alt="logo" />}
            <div className="menu-icon icon-bars" onClick={toggle}></div>
        </div>}
        footer={<FlyoutMenuItemComponent
            title={labels.RESET.TITLE}
            showNavigation={true}
            showContent={true}
            content={<button
                    className="button button--big">
                    {labels.RESET.TITLE}
                </button>}
            nestedContent={<ResetComponent labels={labels.RESET} onConfirm={close}  onCancel={close}/>}
        />}
        menuItems={[
            <FlyoutMenuItemComponent
                title={labels.THIS_IS_ME.TITLE}
                showContent={true}
                content={<div className="flyout-menu-wrapper-this-is-me">
                    <span>{labels.THIS_IS_ME.TITLE}</span>
                    <div className="flyout-menu-wrapper-this-is-me-values">
                        {personal.name && <span>
                            {`${personal.name} | `}
                        </span>}
                        <span>
                            {`${getGenderDesc(personal.gender)} | `}
                        </span>
                        <span>
                            {personal.age}
                        </span>
                    </div>
                </div>}
                nestedContent={<PersonalInfoProvider
                    labels={labels.THIS_IS_ME}
                    onRequiresUpdate={confirm}
                    insideMenu={true}
                    closeMenu={close}>
                    <ThisIsMeComponent />
                </PersonalInfoProvider>}
            />,
            <hr></hr>,
            (renderInflation(tenantConfig.inflation)),
            (!tenantConfig.isClimateScenarioHidden && (<FlyoutMenuItemComponent
                title={labels.CLIMATE_SCENARIO?.TITLE + ' - ' + ClimateScenarioName}
                nestedContent={<ClimateScenarioMenuComponent
                    labels={labels.CLIMATE_SCENARIO}
                    onRequiresUpdate={confirm}
                    onClose={close} />}
            />)),
            <FlyoutMenuItemComponent
                title={labels.LEGEND.TITLE}
                nestedContent={<div  className="flyout-menu-wrapper-legend">
                    <ChartProvider 
                            labels={labels.RETIREMENT_CHART}
                            simulationState={staticSimulationState}
                            showTooltip={false}
                            onRequiresUpdate={onRequiresUpdate}>
                            <RetirementChartComponent />
                    </ChartProvider>
                    <LegendComponent labels={labels.LEGEND}
                        includeIlliquid={userSettings.includeIlliquid} 
                        includeSnapshot={userSettings.includePrevious} 
                        needsLeverage={simulationState.leverageQuarter>0 && userSettings.includeIlliquid} />
                    </div>}
            />,
            <hr></hr>,
            (!isRealEstateHidden && (<FlyoutMenuItemComponent
                content={<ShowPropertyComponent title={labels.PROPERTY} />}
            />)),
            <FlyoutMenuItemComponent
                content={<ShowPreviousValuesComponent title={labels.PREVIOUS} />}
            />,
            <hr></hr>,
            <FlyoutMenuItemComponent
                content={
                    <div>
                        <LanguageSelectComponent
                            useDesc={true}
                            labels={{LANGUAGE: labels.LANGUAGE}} />
                    </div>}
            />,
            <hr></hr>,
            (tenantConfig.isReportDownloadEnabled &&
                (<FlyoutMenuItemComponent
                    content={<div className="flyout-menu-wrapper-download">
                            <DownloadReportComponent
                                title={labels.DOWNLOAD}
                                reportTitle={labels.REPORT_TITLE}
                                reportErrorMessage={labels.REPORT_ERROR_MESSAGE}
                                reportInfoMessage={labels.REPORT_INFO_MESSAGE.replace('{text}', labels.CTA_TEXT)}
                                reportDownloadingMessage={labels.REPORT_DOWNLOADING_MESSAGE}
                                isReportDownloading={isReportDownloading}
                                onReportDowloading={onReportDowloading} 
                                isInsideMenu={true} />
                        </div>}
                />)),
            <hr></hr>,
            <FlyoutMenuItemComponent
                title={labels.INFO_TITLE}
                nestedContent={<InfoComponent labels={labels.INFO} />}
            />
        ]}/>
}