import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { AppLabels } from "../../app.labels";
import { CustomLoaderComponent } from "../../default/components/custom-loader-component/custom-loader-component";
import { GridLoader } from "react-spinners";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { setErrorMessageLabels } from "../store/notifications/notifications.slice";
import { selectTenantConfig } from "../store/tenant-config/tenant-config.slice";
import { selectUserSettingsState } from "../store/user-settings.slice";

export interface LabelsContextProps {
    children: ReactNode;
}

interface LabelsFunctionality {
    labels?: AppLabels;
}

const LabelsContext = createContext<LabelsFunctionality | undefined>(undefined);

export const useLabelsContext = (): LabelsFunctionality => {
    const context = useContext(LabelsContext);
    if (!context) {
        throw new Error('useLabelsContext must be used within a DashboardProvider');
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useContext(LabelsContext)!;
};

export const LabelsProvider: React.FC<LabelsContextProps> = (props: LabelsContextProps) => {
    let abortController = new AbortController();

    const tenantConfig = useAppSelector(selectTenantConfig);
    const userSettings = useAppSelector(selectUserSettingsState);
    const dispatch = useAppDispatch();
    
    const [labels, setLabels] = useState<AppLabels | undefined>();
    const [currentLang, setCurrentLang] = useState<string | undefined>(userSettings.lang ?? tenantConfig.defaultLang);

    useEffect(() => {
        const fetchTenantLabels = async (lang: string | undefined) => {
            const data = await fetch(`./assets/i18n/${lang}.json`, {
              method: "GET",
              headers: { Accepts: "application/json" },
              signal: abortController.signal
            });
        
            const json = await data.json();
            
            const labels: AppLabels = json;
            
            dispatch(setErrorMessageLabels(labels.ERROR_MESSAGES));
            
            return labels;
          };

        abortController.abort();
        abortController = new AbortController();

        fetchTenantLabels(currentLang)
            .then((labels) => {
                setLabels(labels);
            })
            .catch((err) => {
                if (err.name !== 'AbortError') {
                    throw err;
                }
            });
    }, [currentLang]);

    useEffect(() => {
        const langToLoad = userSettings.lang ?? tenantConfig.defaultLang;

        if (langToLoad !== currentLang) {
            setCurrentLang(langToLoad);
        }

    }, [currentLang, userSettings.lang, tenantConfig.defaultLang]);

    const children = labels ? props.children : <CustomLoaderComponent>
        <GridLoader
        className="spinner"
        loading={true}
        />
    </CustomLoaderComponent>;

    const contextValue = {
        labels
    };

    return (
        <LabelsContext.Provider value={contextValue}>{children}</LabelsContext.Provider>
    );

}