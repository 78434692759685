import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import { ConfirmationModalComponent } from "../../../common/components/confirmation-modal-component/confirmation-modal-component";
import { revertAll, useAppDispatch, useAppSelector } from "../../../common/store/hooks";
import { fetchSimulation } from "../../../common/store/simulation/simulation.slice";
import "./menu-component.scss";
import { MenuComponentLabels } from "../../../common/labels/menu-component.labels";
import { selectNotificationsState } from "../../../common/store/notifications/notifications.slice";
import { ShowPreviousValuesComponent } from "../show-previous-values-component/show-previous-values-component";
import { ShowPropertyComponent } from "../show-property-component/show-property-component";
import { LanguageSelectComponent } from "../language-select-component/language-select-component";
import { trackEventActions, trackEventCategories, trackEventNames, useTrackEvent } from "../../../common/hooks/useTrackEvent";
import { NotificationType } from "../../../common/store/notifications/notifications.state";
import { useComponentVisibility } from "../../../common/hooks/useComponentVisibility";
import { persistor } from "../../../common/store/store";
import { DownloadReportComponent } from "../download-report-component/download-report-component";
import { selectTenantConfig } from "../../../common/store/tenant-config/tenant-config.slice";
import { useLocalStorage } from "usehooks-ts";
import { useDashboardContext } from "../../../common/providers/dashboard-provider";


export interface MenuComponentProps {
    labels: MenuComponentLabels;
  }

  
export function MenuComponent(props: MenuComponentProps) {
    let abortController = new AbortController();
    const [visits] = useLocalStorage('visits', 0);
    const notifications = useAppSelector(selectNotificationsState);
    const tenantConfig = useAppSelector(selectTenantConfig);
    const dispatch = useAppDispatch();

    const {
        isReportDownloading,
        onReportDowloading
    } = useDashboardContext();

    const isRealEstateHidden = useComponentVisibility("I_HAVE_REAL_ESTATE");

    const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState<boolean>(false);
    const [isNotificationDotDisable, setIsNotificationDotDisable] = useState<boolean>(false);

    const trackEvent = useTrackEvent();

    function closeConfirmation() {
        trackEvent({ category: trackEventCategories.RESET, action: trackEventActions.CLICK, name: trackEventNames.CLOSE_CONFIRM });
        setIsConfirmationPopupOpen(false);
    }
    
    function openConfirmation() {
        trackEvent({ category: trackEventCategories.RESET, action: trackEventActions.CLICK, name: trackEventNames.OPEN_CONFIRM });
        setIsConfirmationPopupOpen(true);
    }

    function reset() {
        trackEvent({ category: trackEventCategories.RESET, action: trackEventActions.CLICK, name: trackEventNames.CONFIRM });
        persistor.purge();
        dispatch(revertAll());
        setIsConfirmationPopupOpen(false);
        abortController.abort();
        abortController = new AbortController();
        dispatch(fetchSimulation({ abortSignal: abortController.signal }));
    }

    useEffect(() => {
        if (notifications.infoMessages.filter(i => i.type === NotificationType.CONFIG_CHART).length <= 0) {
            setTimeout(() => {
                setIsNotificationDotDisable(true);
            }, 5000);
        }
    }, []);

    useEffect(() => {
        if (visits <= 3) {
            setIsNotificationDotDisable(false);
        }
      }, [visits]);
    

    return(
        <>
        <Popup
            trigger={<label className="menu-icon icon-bars">
                {!isNotificationDotDisable && <span className="menu-notify-dot"></span>}
            </label>}
            position="left bottom"
            on="hover"
            closeOnDocumentClick
            mouseLeaveDelay={300}
            mouseEnterDelay={0}
            contentStyle={{ padding: '0px', border: 'none' }}
            arrow={false}
            onOpen={() => setIsNotificationDotDisable(true)}
            >
            <div className="menu">
                {!isRealEstateHidden &&
                    <div className="menu-item">
                        <ShowPropertyComponent title={props.labels.PROPERTY} />
                    </div>
                }
                <div className="menu-item">
                    <ShowPreviousValuesComponent title={props.labels.PREVIOUS} />
                </div>
                <div className="menu-item">
                    <LanguageSelectComponent className="menu-item" labels={{LANGUAGE: props.labels.LANGUAGE}} />
                </div>
                {tenantConfig.isReportDownloadEnabled && <div className="menu-item">
                    <DownloadReportComponent
                        title={props.labels.DOWNLOAD}
                        reportTitle={props.labels.REPORT_TITLE}
                        reportErrorMessage={props.labels.REPORT_ERROR_MESSAGE}
                        reportInfoMessage={props.labels.REPORT_INFO_MESSAGE.replace('{text}', props.labels.CTA_TEXT)}
                        reportDownloadingMessage={props.labels.REPORT_DOWNLOADING_MESSAGE}
                        isReportDownloading={isReportDownloading}
                        onReportDowloading={onReportDowloading}
                        isInsideMenu={true} />
                </div>}
                <div className="menu-item" onClick={openConfirmation}> {props.labels.RESET}</div>
            </div>
        </Popup>
        <ConfirmationModalComponent
                header={props.labels.RELOAD}
                body={props.labels.RELOAD_CONFIRM}
                buttonText={props.labels.CONFIRM}
                isOpen={isConfirmationPopupOpen}
                closePopup={closeConfirmation}
                confirm={reset}
                /> 
        </>
    );
}