import { GoalLabels, GoalsLabels } from "@flexfront/models";
import { ClimateScenarioComponentLabels } from "./climate-scenario-component.labels";
import { DashboardLabels } from "./dashboard.labels";
import { useLayoutLabels } from "../hooks/useLayoutLabels";
import { IWantComponentLabels } from "./i-want-component.labels";
import { InflationComponentLabels } from "./inflation-modal-component.labels";
import { InfoLabel } from "./info-component.labels";
import { LegendComponentLabels } from "./legend-component-labels";
import { Language } from "./menu-component.labels";
import { ResetComponentLabels } from "./reset-component.labels";
import { RetirementChartComponentLabels } from "./retirement-chart-component.labels";
import { ThisIsMeComponentLabels } from "./this-is-me-component.labels";
import { CtaComponentLabels } from "./cta-component.labels";

export interface FlyoutMenuComponentLabels {
     TITLE: string;
     THIS_IS_ME_TITLE: string;
     INFLATION_TITLE: string;
     CLIMATE_SCENARIO_TITLE: string;
     LEGEND_TITLE: string;
     LANGUAGE_TITLE: string;
     INFO_TITLE: string;
     INFO: InfoLabel;
     RESET_CANCEL: string;
}

export interface FlyoutMenuWrapperComponentLabels {
    TITLE: string;
    THIS_IS_ME: ThisIsMeComponentLabels;
    GENDER_TYPES: any;
    INFLATION_SELECTION: InflationComponentLabels;
    CLIMATE_SCENARIO: ClimateScenarioComponentLabels;
    RETIREMENT_CHART: RetirementChartComponentLabels;
    LEGEND: LegendComponentLabels;
    PROPERTY: string;
    PREVIOUS: string;
    LANGUAGE: Language;
    INFO_TITLE: string;
    INFO: InfoLabel;
    RESET: ResetComponentLabels;
    DOWNLOAD: string;
    REPORT_TITLE: string;
    REPORT_ERROR_MESSAGE: string;
    REPORT_INFO_MESSAGE: string;
    REPORT_DOWNLOADING_MESSAGE: string;
    CTA_TEXT: string;
}

export function fromDashboardAndFlyoutMenuLabels(dashboardLabels: DashboardLabels,goalLabels : GoalsLabels, flyoutMenuLabels: FlyoutMenuComponentLabels,ctaLabels:CtaComponentLabels): FlyoutMenuWrapperComponentLabels {
    return {
        TITLE: flyoutMenuLabels.TITLE,
        THIS_IS_ME: {
            ...dashboardLabels.THIS_IS_ME,
            TITLE: flyoutMenuLabels.THIS_IS_ME_TITLE
        },
        GENDER_TYPES: dashboardLabels.THIS_IS_ME.PERSONAL.GENDER_TYPES,
        INFLATION_SELECTION: {
            ...dashboardLabels.I_WANT,
            GOALS:goalLabels,
            OPEN_BUTTON: dashboardLabels.INFLATION?.OPEN_BUTTON,
            TITLE: flyoutMenuLabels.INFLATION_TITLE,
            DESC: dashboardLabels.INFLATION?.DESC,
            SELECTION: dashboardLabels.INFLATION?.SELECTION,
            EMPTY: dashboardLabels.INFLATION?.EMPTY,
            CONFIRM: dashboardLabels.INFLATION?.CONFIRM
        },
        CLIMATE_SCENARIO: {
            ...dashboardLabels.CLIMATE_SCENARIO,
            TITLE: flyoutMenuLabels.CLIMATE_SCENARIO_TITLE
        },
        RETIREMENT_CHART:{...dashboardLabels.RETIREMENT_CHART},
        LEGEND: {
            ...dashboardLabels.LEGEND,
            TITLE: flyoutMenuLabels.LEGEND_TITLE
        },
        LANGUAGE: {
            ...dashboardLabels.MENU.LANGUAGE,
            TITLE: flyoutMenuLabels.LANGUAGE_TITLE
        },
        PROPERTY: dashboardLabels.MENU.PROPERTY,
        PREVIOUS: dashboardLabels.MENU.PREVIOUS,
        INFO_TITLE: flyoutMenuLabels.INFO_TITLE,
        INFO: flyoutMenuLabels.INFO,
        RESET: {
            TITLE: dashboardLabels.MENU.RESET,
            DESC: dashboardLabels.MENU.RELOAD_CONFIRM,
            CONFIRM: dashboardLabels.MENU.RESET,
            CANCEL: flyoutMenuLabels.RESET_CANCEL
        },
        DOWNLOAD: dashboardLabels.MENU.DOWNLOAD,
        REPORT_TITLE: dashboardLabels.MENU.REPORT_TITLE,
        REPORT_ERROR_MESSAGE: dashboardLabels.MENU.REPORT_ERROR_MESSAGE,
        REPORT_INFO_MESSAGE: dashboardLabels.MENU.REPORT_INFO_MESSAGE,
        REPORT_DOWNLOADING_MESSAGE: dashboardLabels.MENU.REPORT_DOWNLOADING_MESSAGE,
        CTA_TEXT: ctaLabels.PERSONA.TITLE
    }
}