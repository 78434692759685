import { FocusEvent, forwardRef, Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { CurrencyConfig, CurrencyInputFormat, CurrencyInputFormatter } from '../shared/currency.formatter';
import { selectAllText } from '../shared/handlers';
import MaskedInput from 'react-text-mask';
import { createNumberMask } from 'text-mask-addons';

export interface MoneyInputProps {
  placeholder?: string;
  className?: string;
  groupedClassNameSuffix?: string;
  disabled?: boolean;
  inputNumber: number;
  tabIndex?: number;
  currencyConfig?: CurrencyConfig;
  currencyFormatter: CurrencyInputFormatter;
  onChange?: (outputValue: number) => void;
  onBlur?: () => void;
  autoWidth?: boolean;
  showCurrencySymbol?: boolean;
  showIncrementAndDecrement?: boolean;
  stepValue?: number;
  maxValue?: number;
  suffix?: string;
}

export const MoneyInput = forwardRef((props: MoneyInputProps, ref: Ref<HTMLInputElement | null>) => {
  const inputRef = useRef<MaskedInput>(null);

  const [currencyInputValue, setCurrencyInputValue] = useState<CurrencyInputFormat>(
    props.currencyFormatter(props.inputNumber, props.currencyConfig)
  );
  const [placeholder, setPlaceholder] = useState<string | undefined>(props.placeholder);  

  function inputValueChanged(event: React.ChangeEvent<HTMLInputElement>) {
    const currentInputValue = Number(event.target.value.replace(/\D+/g, ''));

    if (!props.maxValue || currentInputValue <= props.maxValue) {
      updateValue(currentInputValue);
    }
  }

  function updateValue(value: number) {
    if (value > -1 && value !== props.inputNumber) {
      setCurrencyInputValue(props.currencyFormatter(value, props.currencyConfig));
      if (props.onChange) {
        props.onChange(value);
      }
    }
  }

  function increment() {
    const step = props.stepValue ?? 1;
    updateValue(props.inputNumber + step);
  }

  function decrement() {
    const step = props.stepValue ?? 1;
    updateValue(props.inputNumber - step);
  }

  function onInputFocussed(event: FocusEvent<HTMLInputElement>) {
    selectAllText(event);
  }

  function focusInput() {
    if (inputRef && inputRef.current) {
      inputRef.current.inputElement.focus();
    }
  }

  function onBlur() {
    if (props.onBlur) {
      props.onBlur();
    }
  }

  useEffect(() => {
    setPlaceholder(props.placeholder);
  }, [props.placeholder]);

  useEffect(() => {
    setCurrencyInputValue(props.currencyFormatter(props.inputNumber, props.currencyConfig));
  }, [props.currencyConfig, props.inputNumber]);

  useEffect(() => {
    if (props.autoWidth && inputRef.current) {
      if (currencyInputValue.value !== '0') {
        inputRef.current.inputElement.style.width = currencyInputValue.value.length + 'ch';
      } else if (props.placeholder) {
        inputRef.current.inputElement.style.width = props.placeholder.length + 'ch';
      }
    }
  });

  useImperativeHandle(ref, () => inputRef.current?.inputElement as HTMLInputElement ?? null);

  const defaultMaskOptions = {
    prefix: !props.currencyConfig?.hasSymbolSuffix ? `${currencyInputValue.symbol}` : '',
    suffix: (props.currencyConfig?.hasSymbolSuffix ? ` ${currencyInputValue.symbol} ` : '') + (props.suffix ?? ''),
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: props.currencyConfig?.groupSeparator,
    allowDecimal: false,
    decimalSymbol: props.currencyConfig?.decimalSeparator,
    decimalLimit: 0,
    integerLimit: 7,
    allowNegative: false,
    allowLeadingZeroes: false,
  }

  const currencyMask = createNumberMask(defaultMaskOptions);

  let moneyInput = (
    <MaskedInput
      inputMode="numeric"
      tabIndex={props.tabIndex}
      placeholder={placeholder}
      mask={currencyMask}
      className={props.className}
      disabled={props.disabled}
      value={currencyInputValue.value}
      onFocus={onInputFocussed}
      onChange={inputValueChanged}
      onBlur={onBlur}
      ref={inputRef}
    />
  );

  if (currencyInputValue.symbol) {
    moneyInput = (
      <div
        onClick={focusInput}
        className={`input-icon-group ${
          props.groupedClassNameSuffix ? `input-icon-group-${props.groupedClassNameSuffix}` : ''
        }`}
      >
        {moneyInput}
      </div>
    );
  }

  if (props.showIncrementAndDecrement) {
    moneyInput = (
      <div className="money-input-container">
        <div className="money-input-button" onClick={() => decrement()}>
          -
        </div>
        {moneyInput}
        <div className="money-input-button" onClick={() => increment()}>
          +
        </div>
      </div>
    );
  }

  return moneyInput;
});

export default MoneyInput;
